import { UserTypeEnum } from "../types/common";
import { QuoteApprovalStatusEnum } from "../types/quote";

export const getFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const setInSessionStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const setInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const convertStringToDate = (date: string) => {
  return new Date(date).toISOString();
};

export const convertISOToReadableDate = (isoDate: string) => {
  return isoDate.substring(0, 10).split("-").reverse().join("-");
};

export const addDaysToDate = (date: string, numberOfDaysToBeAdded: number) => {
  var dateObj = new Date(date);
  dateObj.setHours(9);
  dateObj.setMinutes(0);
  dateObj.setMilliseconds(0);
  dateObj.setDate(dateObj.getDate() + numberOfDaysToBeAdded);
  return dateObj.toISOString();
};

export const getSkuDescription = (sku: any) => {
  const returnValue = [];
  if (sku.watt) {
    returnValue.push({ key: "Watt", value: sku.watt });
  }
  if (sku.colorTemp) {
    returnValue.push({ key: "Led Color", value: sku.colorTemp });
  }

  if (sku.bodyColor) {
    returnValue.push({ key: "Body Color", value: sku.bodyColor });
  }

  if (sku.cutout) {
    returnValue.push({ key: "Cutout", value: sku.cutout });
  }
  if (sku.driverOutput) {
    returnValue.push({ key: "Driver Output", value: sku.driverOutput });
  }
  if (sku.dimension) {
    returnValue.push({ key: "Dimension", value: sku.dimension });
  }
  if (sku.beamAngle) {
    returnValue.push({ key: "Beam Angle", value: sku.beamAngle });
  }
  if (sku.ledUsed) {
    returnValue.push({ key: "Led Used", value: sku.ledUsed });
  }
  if (sku.bodyType) {
    returnValue.push({ key: "Body Type", value: sku.bodyType });
  }
  if (sku.isWaterProof) {
    returnValue.push({ key: "WaterProof", value: "yes" });
  }
  if (sku.ipRating) {
    returnValue.push({ key: "IP Rating", value: sku.ipRating });
  }

  if (sku.customField.length > 0) {
    sku.customField.map((input: any) => {
      //@ts-ignore
      if (input.length == 2) {
        returnValue.push({ key: input[0], value: input[1] });
      }
    });
  }
  return returnValue;
};

export const formatRecievedDescription = (description: any): string => {
  var res = "";

  if (description) {
    for (let i = 0; i < description.length; i++) {
      res += `${description[i].key} : ${description[i].value}`;
    }
  }
  return res;
};

export const colorsForQuoteStatus = (status: string) => {
  switch (status) {
    case QuoteApprovalStatusEnum.Values.Pending:
      return "info";
    case QuoteApprovalStatusEnum.Values.Accept:
      return "success";
    case QuoteApprovalStatusEnum.Values.Reject:
      return "error";
  }
};

export function isOwner(): boolean {
  return (
    getFromSessionStorage("userType") === UserTypeEnum.Values.owner ||
    getFromSessionStorage("userType") === UserTypeEnum.Values.app_admin
  );
}
export function isAdmin(): boolean {
  return getFromSessionStorage("userType") === UserTypeEnum.Values.admin;
}

export const numberInputOnWheelPreventChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

// write a function to return a random 16 character hex string
export const generateRandomString = () => {
  return Math.random().toString(16).substring(2, 16);
};
