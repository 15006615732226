import { z } from "zod";
import { SKUQuantityTypeSchema, UlidSchema } from "../common";
export const QuoteStatusEnum = z.enum([
  "Open",
  "Ledger",
  "Closed",
  "Cancelled",
  "Payment Verified",
  "All",
]);
export type QuoteStatus = z.infer<typeof QuoteStatusEnum>;
export const QuoteApprovalStatusEnum = z.enum(["Accept", "Reject", "Pending"]);
export type QuoteApprovalStatus = z.infer<typeof QuoteApprovalStatusEnum>;
export const QuoteTypeEnum = z.enum(["Product Quote", "Design Quote"]);
export type QuoteType = z.infer<typeof QuoteTypeEnum>;

export const QuoteProductSchema = z.object({
  id: UlidSchema,

  // quote_id: quote_doc.shape.id,
  quote_id: UlidSchema,
  //since there might be custom quotes
  sku_id: UlidSchema.nullish(),
  is_custom: z.boolean(),
  created_code: z.string().min(1).max(255),
  sku_details: z.record(z.any()).nullish(),

  total_excluding_discount: z.number(),
  quantity_type: SKUQuantityTypeSchema,
  warranty: z.string().or(z.number()),

  quantity: z.coerce.number().min(0),
  unit_price: z.coerce.number().min(0),
  discount: z.coerce.number().min(0).max(100),
  total: z.coerce.number().min(0),

  image_path: z.array(z.string().min(2).max(255)).nullish(),
  //meta data
  created_by_id: UlidSchema,
  created_at: z.date(),
  updated_at: z.date(),
});

export type QuoteProduct = z.infer<typeof QuoteProductSchema>;

export const quote_doc = z.object({
  id: UlidSchema,
  quote_number: z.string(),
  quote_date: z.coerce.date(),
  quote_expiry_date: z.coerce.date(),
  quote_status: QuoteStatusEnum,
  quote_approval_status: QuoteApprovalStatusEnum,
  quote_remark: z.string().nullish(),
  quote_type: QuoteTypeEnum,

  //customer details
  customer_name: z.string().min(1).max(255),
  customer_phone: z.string().min(10).max(10),
  customer_id: UlidSchema,

  //architect details
  //it is nullish since end client may not have an architect
  architect_name: z.string().min(1).max(255).nullish(),
  architect_phone: z.string().min(10).max(10).nullish(),
  architect_id: UlidSchema.nullish(),

  //site details
  site_name: z.string().min(1).max(255),
  site_id: UlidSchema,
  site_address: z.string().min(1).max(255),

  //employee details
  employee_name: z.string().min(1).max(255),
  employee_phone: z.string().min(10).max(10),
  employee_id: UlidSchema,

  //quote details
  sub_total: z.number(),
  is_igst: z.boolean(),
  cgst: z.number(),
  sgst: z.number(),
  igst: z.number(),
  logistics_cost: z.number(),
  installation_cost: z.number(),
  total_discount: z.number(),
  total: z.number(),
  product_discount: z.number(),
  sub_total_excluding_discount: z.number(),
  //meta data
  created_by_id: UlidSchema,
  branch_id: UlidSchema,
  created_at: z.date(),
  updated_at: z.date(),
});

export const QuoteDetailSchema = z.object({
  quote: quote_doc,
  quote_item_list: z.array(QuoteProductSchema),
});

export type QuoteDetail = z.infer<typeof QuoteDetailSchema>;

export type Quote = {
  id: string;
  quote_number: string;
  quote_date: string;
  quote_expiry_date: string;
  quote_status: QuoteStatus;
  quote_accepted_at: string | null;
  quote_approval_status: QuoteApprovalStatus;
  quote_remark: string | null;
  quote_type: string;
  customer_name: string;
  customer_phone: string;
  customer_id: string;
  architect_name: string;
  architect_phone: string;
  architect_id: string;
  site_name: string;
  site_id: string;
  employee_name: string;
  employee_phone: string;
  employee_id: string;
  sub_total: number;
  is_igst: boolean;
  cgst: number;
  sgst: number;
  igst: number;
  logistics_cost: number;
  installation_cost: number;
  total_discount: number;
  total_rn_amount: number;
  total_rn_amount_balance: number;
  total: number;
  created_by_id: string;
  total_po: number;
  payout_record_count: number;
  total_payout: number;
  total_dc: number;
  total_rn: number;
  total_rep: number;
  payment_record_count: number;
  total_amount_to_be_paid: number;
  profit: number;
  total_amount: number;
  branch_id: string;
  created_at: string;
  updated_at: string;
  site_address: string;
};

export const TaxEnum = z.enum(["GST", "IGST"]);
export type Tax = z.infer<typeof TaxEnum>;

export const QuoteUpdateSchema = z.object({
  id: UlidSchema,
  is_igst: z.boolean(),
  total_discount: z.number().min(0),
  installation_cost: z.number().min(0),
  logistics_cost: z.number().min(0),
  sku_list: z.array(
    QuoteProductSchema.pick({
      id: true,
      warranty: true,
      unit_price: true,
      discount: true,
    }),
  ),
});

export type QuoteUpdate = z.infer<typeof QuoteUpdateSchema>;
