import { useContext } from "react";
import { SnackbarContext, SnackbarPropType } from "../App";

const useSnackbar = () => {
  const { snackbar, setSnackbar } = useContext<{
    snackbar: SnackbarPropType;
    setSnackbar: (snackbar: SnackbarPropType) => void;
  }>(SnackbarContext);
  return { snackbar, setSnackbar };
};

export default useSnackbar;
