import { io, Socket } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL =
  process.env.NODE_ENV === "production"
    ? "https://backend.lavishkallp.com"
    : "http://192.168.1.2:4000";

export const socket: Socket<any, any> = io(URL, {
  auth: {
    token: `${sessionStorage.getItem("token")}`,
  },
});
