import { z } from "zod";

export const UlidSchema = z.string().ulid();

export const SKUQuantityTypeSchema = z.enum(["mtr", "qty", "sqft", "ft"]);
export type SkuQuantityType = z.infer<typeof SKUQuantityTypeSchema>;

export const OrderByEnum = z.enum(["asc", "desc"]);
export type OrderBy = z.infer<typeof OrderByEnum>;

export const QuoteLedgerStatusEnum = z.enum([
  "Open",
  "Ledger",
  "Closed",
  "Cancelled",
]);
export const UserTypeEnum = z.enum([
  "employee",
  "admin",
  "owner",
  "app_admin",
  "warehouse_employee",
  "dispatch_employee",
]);

export const PaymentModeSchema = z.enum([
  "cash",
  "upi",
  "card",
  "mobile and internet banking",
  "cheque",
]);

export type PaymentMode = z.infer<typeof PaymentModeSchema>;

export const PayoutCategorySchema = z.enum([
  "installation",
  "delivery",
  "to architect",
  "partners",
]);
export type PayoutCategory = z.infer<typeof PayoutCategorySchema>;
