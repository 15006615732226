import Axios, { AxiosRequestConfig } from "axios";
import { backendConfig } from "../config/constants";
import { getFromSessionStorage } from "../utils/HelperFunctions";

//authRequestInterceptor will add the jwt token to request headers;
function authRequestInterceptor(config: AxiosRequestConfig) {
  //add token to request headers here
  if (config.headers) {
    const token = getFromSessionStorage("token");
    if (token) {
      config.headers.authorization = `${token}`;
    }
    config.headers.Accept = "application/json";
  }
  return config;
}
export const axios = Axios.create({
  baseURL: backendConfig.baseURL,
});

axios.interceptors.request.use(authRequestInterceptor);
