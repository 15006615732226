import {
  Button,
  Card,
  styled,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import validator from "validator";
import { auth, CompanyName } from "../config/constants";
import {
  getFromSessionStorage,
  setInSessionStorage,
} from "../utils/HelperFunctions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useSnackbar from "../hooks/useSnackbar";
import { useSignIn } from "../api/login";
import { useNavigate } from "react-router-dom";
import { SignInResponse } from "../types/login";
import { axios } from "../lib/axios";
import { UserTypeEnum } from "../types/common";

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    marginRight: "2rem",
    marginLeft: "2rem",
    height: "90vh",
  },
  alignItems: "center",
  height: "100vh",
  marginRight: "2rem",
  marginLeft: "2rem",
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1",
  height: "100%",
  justifyContent: "center",
}));

const LoginFormBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "8px",
  flex: "1",
  gap: "1rem",
});

function Login({ taskEvent }: { taskEvent: { task_count: number } }) {
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const signInMutation = useSignIn();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberHelperText, setphoneNumberHelperText] = useState("");

  const [password, setpassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const [changePasswordText, setChangePasswordText] = useState("");
  const [defaultPasswordError, setdefaultPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSearchWithEnterKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      loginSubmitButtonHandler();
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const loginSubmitButtonHandler = () => {
    if (!validator.isMobilePhone(phoneNumber, ["en-IN"])) {
      setPhoneNumberError(true);
      setphoneNumberHelperText("Invalid phone number");
      return;
    }
    if (
      validator.isEmpty(password) ||
      !validator.isLength(password, { min: 6, max: 20 })
    ) {
      setPasswordError(true);
      setPasswordHelperText(
        "Invalid password. Password must have atleast 6 characters.",
      );
      return;
    } else {
      signInMutation.mutate(
        { phone: phoneNumber, password: password },
        {
          onSuccess: (response) => {
            const result = response.data.result as SignInResponse;
            const user = result.user;
            const authToken = result.auth_token;
            setInSessionStorage("isLoggedIn", "true");
            setInSessionStorage("expiry", authToken.expires_in);
            setInSessionStorage("token", authToken.token);
            setInSessionStorage("task_count", taskEvent.task_count.toString());
            //            axios.defaults.headers.common["Authorization"] = authToken.token;
            setInSessionStorage("userType", user.user_type);
            setInSessionStorage(
              "isDefaultPassword",
              user.is_default_password.toString(),
            );
            if (!user["is_default_password"]) {
              if (user.user_type === UserTypeEnum.Values.warehouse_employee) {
                navigate("/warehouse/purchaseOrder");
              } else if (
                user.user_type === UserTypeEnum.Values.dispatch_employee
              ) {
                navigate("/dc/dispatch");
              } else {
                navigate("/clients");
              }
              setSnackbar({
                open: true,
                message: "Logged in successfully",
                severity: "success",
              });
            } else {
              setChangePasswordDialogOpen(true);
            }
          },
          onError: (error: any) => {
            setSnackbar({
              open: true,
              message: error.message,
              severity: "error",
            });
            console.log("Failed to log in", error.message);
          },
        },
      );
    }
  };

  const handleDialogClose = () => {
    setChangePasswordDialogOpen(false);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleChangeDefaultPasswordRequest = () => {
    if (
      validator.isEmpty(changePasswordText) ||
      !validator.isLength(changePasswordText, { min: 6, max: 20 })
    ) {
      setdefaultPasswordError(true);
      setPasswordHelperText(
        "Invalid password. Password must have atleast 6 characters.",
      );
      return;
    }
    if (
      getFromSessionStorage("token") !== null ||
      getFromSessionStorage("token") !== ""
    ) {
      // axios.defaults.headers.common["Authorization"] = getFromSessionStorage(
      //   "token",
      // ) as string;
    }
    axios
      .put(
        auth.changeDefaultPassword,
        {
          password: changePasswordText,
        },
        {
          params: {
            branch: getFromSessionStorage("branchID"),
          },
        },
      )
      .then((response) => {
        navigate("/clients");
        setSnackbar({
          open: true,
          message: "Logged in successfully",
          severity: "success",
        });
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: error.response.data.result[0].message,
          severity: "error",
        });

        console.log("Error changing password", error.response.data.result);
      });
  };

  return (
    <MainBox>
      <LogoBox>
        <Typography
          textAlign="center"
          color="primary"
          //         letterSpacing="1.1rem"
          variant="h2"
        >
          {CompanyName.billingName}
        </Typography>
      </LogoBox>

      <Card
        elevation={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          p: { sm: 4, md: 5 },
          m: { sm: 2, md: 4 },
          width: { sm: "50%", md: "30%" },
          borderRadius: 2.5,
          boxShadow: 8,
        }}
      >
        <LoginFormBox>
          <Typography
            color="primary"
            sx={{ mb: 8 }}
            //    fontWeight="bold"
            variant="h4"
          >
            Login
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Phone Number"
            required
            type="number"
            margin="dense"
            helperText={phoneNumberHelperText}
            error={phoneNumberError}
            onChange={(e) => {
              setPhoneNumberError(false);
              setPhoneNumber(e.target.value);
              setphoneNumberHelperText("");
            }}
          />
          <TextField
            fullWidth
            type="password"
            variant="outlined"
            label="Password"
            required
            onKeyDown={handleSearchWithEnterKeyPress}
            helperText={passwordHelperText}
            error={passwordError}
            margin="dense"
            onChange={(e) => {
              setPasswordError(false);
              setpassword(e.target.value);
              setPasswordHelperText("");
            }}
          />
          <Button
            sx={{ mt: 2, width: "50%" }}
            variant="contained"
            disabled={
              phoneNumber === "" || password === "" || signInMutation.isPending
            }
            onClick={loginSubmitButtonHandler}
          >
            Login
          </Button>
          <Button sx={{}}>Forgot password?</Button>
        </LoginFormBox>
      </Card>
      <Dialog open={changePasswordDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Change default password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change your default password
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type={!showPassword ? "password" : "text"}
            fullWidth
            helperText={passwordHelperText}
            error={defaultPasswordError}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPasswordError(false);
              setPasswordHelperText("");

              setChangePasswordText(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleChangeDefaultPasswordRequest}>Submit</Button>
        </DialogActions>
      </Dialog>
    </MainBox>
  );
}

export default Login;
