import { useMutation } from "@tanstack/react-query";
import { axios } from "../../lib/axios";

const signInUser = ({
  phone,
  password,
}: {
  phone: string;
  password: string;
}) => {
  return axios.post("/auth/signin", { phone, password });
};

const useSignIn = () => {
  return useMutation({
    mutationFn: async ({
      phone,
      password,
    }: {
      phone: string;
      password: string;
    }) => {
      return signInUser({ phone, password });
    },
  });
};

export { useSignIn };
