import {
  Alert,
  AlertColor,
  Box,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { SnackbarContext } from "../../App";

function Notification({
  open,
  severity,
  message,
}: {
  open: boolean;
  severity: AlertColor | undefined;
  message: string;
}) {
  const { snackbar, setSnackbar } = useContext(SnackbarContext);
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (open) {
      if (severity === "success") {
      }
      const interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 10,
        );
      }, 450);
      return () => {
        clearInterval(interval);
        setProgress(0);
      };
    }
  }, [open]);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        sx={{
          minWidth: "20%",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box
          sx={{
            boxShadow: 1,
            borderRadius: 2,
            width: "100%",
            bgcolor: "white",
          }}
        >
          <Alert severity={severity} onClose={handleSnackbarClose} sx={{}}>
            {message}
          </Alert>
          <LinearProgress
            color={severity}
            variant="determinate"
            value={progress}
          />
        </Box>
      </Snackbar>
    </div>
  );
}

export default Notification;
